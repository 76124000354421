<template>
  <div class="ion-page">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <ion-content>
      <app-audio v-if="pageVariant=='audio'"></app-audio>
    </ion-content>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import AppAudio from '../components/AppAudio.vue';

export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  data: function() {
    return{
      isLoading: false,
      fullPage: true,
      searchText: "",
      pageVariant: process.env.VUE_APP_SKIN
    }
  },
  components: {
    Loading,
    AppAudio,

  },
  mounted() {},
  beforeUnmount() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
}
.btn {
  margin-top: 20px;
}
.receiver-link-card {
  --background-activated: #db3939;
  color: #ffffff;
}
ion-card-content {
  color: #000000;
}
ion-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.displayVideo {
  width: 700px;
  margin-inline: 37%;
}
.selectVideo {
  margin: auto;
  width: 300px;
}
</style>
