<template>
  <div class="ion-page">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <ion-header>
      <ion-toolbar color="primary-contrast">
        <div class="ion-text-center">
          <div class="header-logos">
            <ion-icon
              v-if="isOfflineReady"
              icon="md-radio-button-on"
              size="large"
              color="success"
            ></ion-icon>
            <ion-icon
              v-if="!isOfflineReady"
              icon="md-radio-button-off"
              size="large"
              color="danger"
            ></ion-icon>
            <ion-thumbnail class="logo-header">
              <img src="../assets/images/05_Logo_Ollomont.png" />
            </ion-thumbnail>
            <ion-thumbnail class="logo-header">
              <img src="../assets/images/Stemma_Valle_d'Aosta.png" />
            </ion-thumbnail>
            <ion-thumbnail class="logo-header" style="margin-top:-50px">
              <img
                src="../assets/images/logo_MINIERE_DI_OLLOMONT_VALPELLINE.png"
              />
            </ion-thumbnail>
            <ion-icon
              v-if="!isOfflineReady"
              icon="md-time"
              size="large"
              color="danger"
            ></ion-icon>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content>
    <div class="download-again-button" v-show="numFile<audioClips.length" >
      <ion-button @click="clearCache()" color="tertiary">Reset</ion-button>
    </div>
      <div class="audioClips-buttons">
        <!-- Inizio Audio -->
        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[0].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[0].disabled, audioClip: true }"
              @play="playAudio(audioClips[0].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/CIAULA_SCOPRE_LA_LUNA.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[1].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[1].disabled, audioClip: true }"
              @play="playAudio(audioClips[1].code)"
              @pause="stopAudio()"
            >
              <source src="../assets/audioClips/IL_FUMO.wav" type="audio/wav" />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[2].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[2].disabled, audioClip: true }"
              @play="playAudio(audioClips[2].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/MANZETTI_02.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[3].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[3].disabled, audioClip: true }"
              @play="playAudio(audioClips[3].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/MANZETTI_03.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[4].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[4].disabled, audioClip: true }"
              @play="playAudio(audioClips[4].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/MANZETTI_01.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[5].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[5].disabled, audioClip: true }"
              @play="playAudio(audioClips[5].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/MANZETTI_04.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>

        <ion-card class="audioClip-card" size="large" color="primary">
          <div class="audioClip-button-content">
            <p class="ion-text-wrap audioClip-title">{{ audioClips[6].label }}</p>
            <audio
              controls
              controlsList="nodownload"
              :class="{ disabled: audioClips[6].disabled, audioClip: true }"
              @play="playAudio(audioClips[6].code)"
              @pause="stopAudio()"
            >
              <source
                src="../assets/audioClips/MANZETTI_05.wav"
                type="audio/wav"
              />
            </audio>
          </div>
        </ion-card>
        <!-- Fine Audio -->
      </div>
    </ion-content>
    <ion-footer class="ion-text-center">
      <img
        class="logo-footer"
        src="../assets/images/logo_interreg_minove.png"
      />
    </ion-footer>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
//Import components
import Modal from "./Modal.vue";

export default {
  name: "AppAudio",
  props: {
    msg: String,
  },
  data: function() {
    return {
      isLoading: false,
      fullPage: true,
      searchText: "",
      audioClips: [
        {
          label: "Ciàula scopre la luna (L.Pirandello,1907)",
          name: "CIAULA_SCOPRE_LA_LUNA",
          code: 1,
          url: "../assets/audioClips/CIAULA_SCOPRE_LA_LUNA.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Il fumo (L.Pirandello,1904)",
          name: "IL_FUMO",
          code: 2,
          url: "../assets/audioClips/IL_FUMO.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Manzetti 01 Una immensa ruota",
          name: "MANZETTI_02",
          code: 4,
          url: "../assets/audioClips/MONZETTI_02.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Manzetti 02 Un pozzo profondo",
          name: "MANZETTI_03",
          code: 5,
          url: "../assets/audioClips/MONZETTI_03.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Manzetti 03  <<Ci penserò, proverò>>",
          name: "MANZETTI_01",
          code: 3,
          url: "../assets/audioClips/MONZETTI_01.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Manzetti 04 Un'idea luminosa, eureka!",
          name: "MANZETTI_04",
          code: 6,
          url: "../assets/audioClips/MONZETTI_04.wav",
          isDownloaded: false,
          disabled: false,
        },
        {
          label: "Manzetti 05 Una macchina ingegnosa",
          name: "MANZETTI_05",
          code: 7,
          url: "../assets/audioClips/MONZETTI_05.wav",
          isDownloaded: false,
          disabled: false,
        },
      ],
      isOfflineReady: false,
      numFile: 0,
      downloaded: [],
      intervalValue: null,
    };
  },
  components: {
    Loading,
  },

  mounted() {
    document.addEventListener("cachedEvent", this.handleCustomEvent);
    document.addEventListener("readyEvent", this.handleCustomEvent);
    document.addEventListener("downloadingEvent", this.handleCustomEvent);
    this.intervalValue = setInterval(
      this.countDownloadedWavFile.bind(this),
      500
    );
    this.countCachedWavFiles();
  },
  beforeUnmount() {
    document.removeEventListener("cachedEvent", this.handleCustomEvent);
    document.removeEventListener("readyEvent", this.handleCustomEvent);
    document.addEventListener("downloadingEvent", this.handleCustomEvent);
  },
  methods: {
    countCachedWavFiles() {
      //la stringa rappresenta il nome della preCache autogenerato da Workbox
      //questa parte è sempre fissa workbox-precache-v2-
      //va concatenato base Url
      caches
        .open("workbox-precache-v2-" + process.env.VUE_APP_BASE_URL)
        .then((cache) => {
          cache.keys().then((keys) => {
            const wavFiles = keys.filter((request) =>
              request.url.includes(".wav")
            );
            this.numFile = wavFiles.length;
            //genera un evento se il numero di file corrisponde al numero di video caricati
            if (this.numFile == this.audioClips.length) {
              clearInterval(this.intervalValue);
              let readyEvent = new Event("readyEvent");
              readyEvent.value = "readyEvent";
              document.dispatchEvent(readyEvent);
            }
          });
        });
    },
    //conta i file Mp4 che sono stati scaricati
    countDownloadedWavFile() {
      caches
        .open("workbox-precache-v2-" + process.env.VUE_APP_BASE_URL)
        .then((cache) => {
          cache.keys().then((keys) => {
            const wavFiles = keys.filter((request) =>
              request.url.includes(".wav")
            );
            let downloadedFile = new Array(this.audioClips.length);
            wavFiles.forEach((wavFile) => {
              for (let i = 0; i < this.audioClips.length; i++) {
                if (wavFile.url.includes(this.audioClips[i].name)) {
                  downloadedFile[i] = this.audioClips[i].code;
                }
              }
            });
            this.downloaded = downloadedFile;
          });
        });
        let count = 0;
      this.audioClips.forEach((audioClip) => {
        if (audioClip.isDownloaded) {
          count++;
        }
      });
      if (count == this.audioClips.length) {
        window.location.reload();
      }
    },
    presentAlert(header, subtitle, message) {
      return this.$ionic.alertController
        .create({
          header: header,
          subHeader: subtitle,
          message: message,
          buttons: ["OK"],
          backdropDismiss: false,
        })
        .then((a) => a.present());
    },
    handleCustomEvent(event) {
      if (event.value == "cachedEvent") {
        window.location.reload();
      } else if (event.value == "readyEvent") {
        this.isOfflineReady = true;
        this.presentAlert(
          "messaggio",
          "",
          "Il dispositivo può essere utilizzato offline"
        );
      } else if (event.value == "downloadingEvent") {
        this.createModal();
      }
    },
    clearCache() {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          if (cacheName.startsWith("workbox")) {
            caches.delete(cacheName);
            sessionStorage.clear();
            localStorage.clear();
          }
        });
      });
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      }
    },
    playAudio(audioCode) {
      this.audioClips.forEach((audioClip) => {
        if (audioCode !== audioClip.code) {
          audioClip.disabled = true;
        } else {
          audioClip.disabled = false;
        }
      });
    },
    stopAudio() {
      this.audioClips.forEach((audioClip) => {
        audioClip.disabled = false;
      });
    },
    selected(e, selected) {
      if (selected) {
        e.target.style.backgroundColor = "#DB3939";
        e.target.style.color = "#FFFFFF";
      } else {
        e.target.style.backgroundColor = "#FFFFFF";
        e.target.style.color = "#000000";
      }
    },
    createModal() {
      return this.$ionic.modalController
        .create({
          component: Modal,
          componentProps: {
            data: {},
            propsData: {
              title: "Lista degli audio da scaricare :",
              items: this.audioClips,
            },
          },
          backdropDismiss: false,
        })
        .then((m) => m.present());
    },
  },
  computed: {},
  watch: {
    downloaded: {
      handler() {
        this.downloaded.forEach((oldv) => {
          this.audioClips.forEach((audioClip) => {
            if (audioClip.code == oldv) {
              audioClip.isDownloaded = true;
            }
          });
        });
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
}
.btn {
  margin-top: 20px;
}
.receiver-link-card {
  --background-activated: #db3939;
  color: #ffffff;
}
ion-card-content {
  color: #000000;
}
ion-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
ion-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.audioClip-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.audioClip-card {
  border-radius: 50px;
}
.audioClip {
  color: #a9c85d;
}
.audioClip::-webkit-media-controls-panel {
  background-color: #a0b46f;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.audioClip-title{
  margin-left:2%
}
.logo-header {
  width: 100px;
    height: 100px;
}
.logo-footer {
  width: 500px;
}
.header-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-header-3 {
  width: 150px;
  margin-top: -90px;
}
.logo-header-2 {
  width: 100px;
}
.logo-header-1 {
  width: 225px;
}
.download-again-button{
  display: flex;
  justify-content: center;
} 
/* .audioClip-card {
  width: 350px;
  height: 200px;
  border-radius: 50px;
}

.audioClip-button-content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.audioClips-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2%;
}
.audioClip {
  color: #a9c85d;
  margin-top: 10%;
}
.audioClip::-webkit-media-controls-panel {
  background-color: #a0b46f;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.logo-header {
  width: 100px;
    height: 100px;
}
.logo-footer {
  width: 500px;
}
.header-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-header-3 {
  width: 150px;
  margin-top: -90px;
}
.logo-header-2 {
  width: 100px;
}
.logo-header-1 {
  width: 225px;
} */
</style>
